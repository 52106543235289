import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

interface AuthState {
    auth: string
    login:boolean
}


const initialState: AuthState = {
    auth: '',
    login:false
}

export const authSlice = createSlice({
    name: 'authSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<string>) => {
        state.auth = action.payload
        },
        setLogin:(state)=>{
            state.login=true;
        },
        setLogout:(state)=>{
            state.login=false;
            state.auth=''
        }
    },
})

export const { setAuth, setLogin, setLogout } = authSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.authSlice

export default authSlice.reducer