import { useAppSelector } from "../../app/hooks";

// Custom Hook to generate auth headers
export const useAuthHeaders = () => {
    const auth = useAppSelector((state) => state.authSlice.auth);

    const data_header = {
        headers: {
        Authorization: `Bearer ${auth}`,
        "Content-type": "application/json",
        },
    };

    const file_header = {
        headers: {
        Authorization: `Bearer ${auth}`,
        "Content-Type": "multipart/form-data",
        },
    };

    return { data_header, file_header };
    };
