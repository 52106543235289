import React, { useState } from 'react'
import styles from './Login.module.scss'
import hero from '../../assets/hero_mobile.png';
import { Button, Card, FilledInput, TextField } from '@mui/material';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setAuth, setLogin, setLogout } from '../../app/redux_slices/auth_slice';
import { baseUrl } from '../shared/shared';
import { TextInput } from '@tremor/react';
import { setUser } from '../../app/redux_slices/route_slice';
import toast, { Toaster } from 'react-hot-toast';

interface Credentials{
    email:string
    password:string
}

export default function Login() {
    const [credentials, setCredentials]=useState<Credentials>({
        email:"",
        password:""
    });
    const [credentialsCheck, setCredentialsCheck]=useState<boolean>(false);

    const dispatch = useAppDispatch()

    const showError = (errorStatement:string) =>toast.error(errorStatement);
    const showSuccess = (errorStatement:string) =>toast.success(errorStatement);

    async function LoginApi(){
        setCredentialsCheck(false);
        try{
            const response = await axios.post(`${baseUrl}/api/v1/ops-admin/auth/login`,{
                email:credentials.email,
                password:credentials.password
            })
            console.log(response.status)
            if(response.status==200 || response.status==201){
                console.log(response.data)
                if(response.data.accessToken!==''){
                    dispatch(setAuth(response.data.accessToken));
                    dispatch(setLogin());
                    dispatch(setUser());
                    showSuccess('Login Successful!');
                }else{
                    showError('Login Failed Please check the credentials!');
                    setCredentialsCheck(true)
                }
            }
        }catch(error:any){
            showError('Login Failed Please check the credentials!');
            dispatch(setLogout());
            setCredentialsCheck(true)
            //dispatch(setLogin());
            //dispatch(setAuth('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZmJkYjMzOTZlYTkxZTAyNGUxMDgyZCIsImlhdCI6MTcyNzc4MTY5MywiZXhwIjoxNzI5OTQxNjkzfQ.KFYbyZ-lUnt2jq5S10TCR3QXUsxPt_y__cdF506SCsI'));
        }
    }

    return (
        <main>
            <img src={hero} />
            <div className={styles.mainBg} />
            <div className={styles.navBar}>
                <p className={styles.head}>Eagru</p>
                <p className={styles.sideHead}>Ops Panel</p>
            </div>
            <div className={styles.contetnts}>
                <div className={styles.left}>
                    <p>We<br/>Deliver<br/>Better</p>
                </div>
                <div className={styles.right}>
                    <Card className={styles.loginCard}>
                        <p className={styles.title}>Login</p>
                        <div className={styles.inputField}>
                            <div className={styles.inputSection}>
                                <p className={styles.label}>Email</p>
                                <TextInput onValueChange={(value)=>{ setCredentialsCheck(false); setCredentials({
                                    ...credentials,
                                    email:value
                                })}} placeholder='Your Email Id' width={325} type='email' />
                            </div>
                            <div className={styles.inputSection}>
                                <p className={styles.label}>Password</p>
                                <TextInput onValueChange={(value)=>{ setCredentialsCheck(false); setCredentials({
                                    ...credentials,
                                    password:value
                                })}} placeholder='your password' width={325} type='password' />
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            {credentialsCheck && <p style={{color:"red"}}>*Wrong credentials!</p>}
                            <Button onClick={()=>LoginApi()} variant='contained'>Login</Button>
                        </div>
                    </Card>
                </div>
            </div>
        </main>
    )
}
