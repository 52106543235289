import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../store';

interface RouteState {
    user: boolean;
    cleaner: boolean;
    work: boolean;
}

const initialState: RouteState = {
    user: false,
    cleaner: false,
    work: false,
};

export const routeSlice = createSlice({
    name: 'routeSlice',
    initialState,
    reducers: {
        setUser: (state) => {
            state.user = true;
            state.cleaner = false;
            state.work = false;
        },
        setCleaner: (state) => {
            state.user = false;
            state.cleaner = true;
            state.work = false;
        },
        setWork: (state) => {
            state.user = false;
            state.cleaner = false;
            state.work = true;
        },
        resetRoute: (state) => {
            state.user = false;
            state.cleaner = false;
            state.work = false;
        },
    },
});

export const { setUser, setCleaner, setWork, resetRoute } = routeSlice.actions;

export const selectRoute = (state: RootState) => state.routeSlice;

export default routeSlice.reducer;
