/* import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './redux_slices/auth_slice'

const store = configureStore({
    reducer: {
        auth: authSlice.reducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store */


import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './redux_slices/auth_slice';
import { routeSlice } from './redux_slices/route_slice'; // Import your routeSlice
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';

// Configuration for persistence
const persistConfig = {
    key: 'root',
    storage,
};

// Combine reducers for both authSlice and routeSlice
const rootReducer = combineReducers({
    authSlice: authSlice.reducer,
    routeSlice: routeSlice.reducer, // Add your routeSlice here
});

// Wrap the rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with the persisted reducer
const store = configureStore({
    reducer: persistedReducer,
});

// Persistor to trigger rehydration
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;



