import { useState } from 'react';
import styles from './App.module.scss';
import SideBar from './Pages/SideBar/SideBar';
import Login from './Pages/Login/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Users from './Pages/Users/Users';
import Cleaners from './Pages/Cleaners/Cleaners';
import Work_Logs from './Pages/Work_Logs/Work_Logs';
import { useAppSelector } from './app/hooks';

function App() {
  const auth_token = useAppSelector((state) => state.authSlice.auth);
  const login_status = useAppSelector((state) => state.authSlice.login);
  console.log(login_status);
  if(login_status){
    return (
      <div className={styles.main}>
        <BrowserRouter>
        <SideBar />
          <Routes>
            <Route path='/' element={<Users />} />
            <Route path='/cleaners' element={<Cleaners />} />
            <Route path='/work_logs' element={<Work_Logs />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
    return (
      <Login />
    )
  }

  export default App;
