import { Typography } from '@mui/material'
import styles from './SideBar.module.scss';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@tremor/react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setAuth, setLogout } from '../../app/redux_slices/auth_slice';
import { resetRoute, setCleaner, setUser, setWork } from '../../app/redux_slices/route_slice';
import toast, { Toaster } from 'react-hot-toast';
import { IoLogOutOutline } from "react-icons/io5";

const nav = ["Users", "Cleaners", "Work Logs"]

const navList = [
    {
        navName:"Users",
        direction:"/"
    },
    {
        navName:"Cleaners",
        direction:"/cleaners"
    },
    {
        navName:"Work Logs",
        direction:"/work_logs"
    }


]

function SideBar() {
    const dispatch = useAppDispatch()
    const routeState = useAppSelector(state=> state.routeSlice);
    const [active, setActive]=useState<string>(`${routeState.user ? 'Users' : routeState.cleaner ? 'Cleaners': "Work Logs"}`)

    useEffect(()=>{
        if(routeState.user){
            setActive("Users")
        }
        else if(routeState.cleaner){
            setActive("Cleaners")
        }
        else if(routeState.work){
            setActive("Work Logs")
        }
    },[routeState])

    const showSuccess = (errorStatement:string) =>toast.success(errorStatement);
    return (
        <div className={styles.nav}>
            <Toaster position="top-right" />
            <Typography className={styles.head}>Eagru</Typography>
            <ul className={styles.list}>
                {
                    navList.map(item=>(
                        <Link to={item.direction}><li style={active==item.navName ? {
                            background:"#FFF",borderRight:"5px solid #3F72AF"
                        }: {}} key={item.direction} onClick={()=>{
                            if(item.navName=='Users'){
                                dispatch(setUser())
                            }else if(item.navName=='Cleaners'){
                                dispatch(setCleaner())
                            }else if(item.navName=='Work Logs'){
                                dispatch(setWork())
                            }
                        }}>{item.navName}</li></Link>
                    ))
                }
            </ul>
            <div onClick={()=>{dispatch(setAuth('')); dispatch(setLogout()); dispatch(resetRoute()); showSuccess('Logged Out!')}} className={styles.button}>
            Logout
            <IoLogOutOutline size={25} />
            {/* <div style={{
                            background:"#FFF"
                        }}>Logout</div>
                <Button variant='secondary' color='red'  onClick={()=>{dispatch(setAuth(''));
                                            dispatch(setLogout()); dispatch(resetRoute()); showSuccess('Logged Out!')}}>Logout</Button> */}
            </div>
        </div>
    )
}

export default SideBar