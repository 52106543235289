import { Button, Card, Table, TableBody, TableHeaderCell, TextInput, Title, TableCell, TableHead, TableRow, Badge, BadgeDelta, SearchSelect, SearchSelectItem } from '@tremor/react';
import styles from './Work_Logs.module.scss';
import { RiSearch2Line, RiSearchLine } from '@remixicon/react';
import data from './Work_Logs.json'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import car from '../../assets/car.png';
import { baseUrl } from '../shared/shared';
import axios from 'axios';
import { useAuthHeaders } from '../shared/header';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setLogout } from '../../app/redux_slices/auth_slice';
import toast, { Toaster } from 'react-hot-toast';
import { DatePicker } from './CustomDatePicker';
import { HiOutlineFilter } from "react-icons/hi";


interface TableData{
    _id:string
    cleaner_id:string
    cleaner_name:string
    car_number:string
    timming:string,
    address: {
        society: string,
        locality: string
    },
    parking:{
        floor:string,
        spot_number:string
    },
    work_status:string
    uploads:{
        uuid:string
        s3_url:string
        s3_url_thumbnail:string
        type:string
        latitude:number
        longitude:number
    }[]
    assigned_date:string
    low_resolution_image:string
    high_resolution_image:string
    is_active:boolean
    is_deleted:boolean
}

interface FilterOptions {
    search?: string | undefined;
    page: number;
    status?: string | undefined;
    date?: string | undefined;
    society?: string| undefined ;
    cleaner?:string| undefined ;
  }

function Work_Logs() {
    const [uploadFileView, setUploadFileView]=useState(false);
    const [addFileView, setAddFileView]=useState(false);
    const [deleteView,setDeleteView]=useState(false);
    const [tableData, setTableData]=useState<TableData[] | []>([]);
    const [currentPage, setCurrentPage]=useState<number>(1);
    const [maxPage, setMaxPage]=useState<number>(100);
    const [filterData, setFilterData]=useState<string | undefined>();
    const [imageView, setImageView]=useState(false);
    const [imageLink, setImageLink]=useState('');
    const dispatch = useAppDispatch();
    const [uniqueSocieties, setUniqueSocieties]=useState<string[]>([]);
    const [dateString, setDateString]=useState<string>();
    const [cleaner, setCleaner]=useState<string>();
    const [society, setSociety]=useState<string>();
    const [filterButton, setFilterButton]=useState({
        done:false,
        not_done:false
    });
    const [cleanerList, setCleanerList]=useState<string[]>([]);
    const [soceityList, setSocietyList]=useState<string[]>([]);
    const [filterButtonValue, setFilterButtonValue]=useState<string>();
    const auth = useAppSelector((state) => state.authSlice.auth);
    const [workLogStaus, setWorkLogStatus]=useState({
        done:0,
        notDone:0
    });

    const { data_header, file_header } = useAuthHeaders();

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        if (date) {
            // Format the date as DD-MM-YYYY
            const formattedDate = formatDate(date);
            setDateString(formattedDate);
        } else {
            setDateString(undefined);
        }
        setCurrentPage(1);
    };

    const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    useEffect(()=>{
        const unique = Array.from(
            new Set(tableData.map((data) => data.address.society))
        );
        setUniqueSocieties(unique);
    },[tableData])

    const showError = (errorStatement:string) =>toast.error(errorStatement);

    useEffect(()=>{
        filterSearch({page:1});
    },[]);

/*     async function getTableData(page:number){
        console.log(page);
        setTableData([]);
        try{
            const response = await axios.get(`${baseUrl}/api/v1/ops-admin/cleaners/get-cleaner-work-logs?page=${page}&limit=10`, data_header);
            if(response.status===200 || response.status===201){
                console.log(response.data.data);
                setTableData(response.data.data);
                setCurrentPage(response.data.currentPage);
                setMaxPage(response.data.totalPages);
            }
        }catch(error:any){
            if(error.response.status===401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            showError('Failed to load the table!');
            console.log(error.status)
        }
    } */
    async function getAllCleaner(){
        try {
            const response = await axios.get(`${baseUrl}/api/v1/ops-admin/cleaners/get-distinct-cleaner`, data_header);
            if(response.status===200){
                setCleanerList(response.data.data);
            }
        } catch (error) {
            showError('No Cleaner found!');
        }
    }

    async function getAllSociety(){
        try {
            const response = await axios.get(`${baseUrl}/api/v1/ops-admin/cleaners/get-distinct-society`,data_header);
            if(response.status===200){
                setSocietyList(response.data.data);
            }
        } catch (error) {
            showError('No Society found!');
        }
    }

    useEffect(()=>{
        getAllCleaner();
        getAllSociety();
    },[])
    async function filterSearch({page,date=undefined,search=undefined,society=undefined,status=undefined,cleaner=undefined  }:FilterOptions){
        let url = `api/v1/ops-admin/cleaners/get-cleaner-work-logs?page=${page}&limit=10`;

        // Conditionally append optional parameters if they are defined
        if (search) url += `&search=${encodeURIComponent(search)}`;
        if (date) url += `&date=${encodeURIComponent(date)}`;
        if (society) url += `&society=${encodeURIComponent(society)}`;
        if (status) url += `&status=${encodeURIComponent(status)}`;
        if (cleaner) url += `&cleaner_name=${encodeURIComponent(cleaner)}`;
        console.log(url);
        console.log(auth);
        try{
            const response = await axios.get(`${baseUrl}/${url}`, data_header);
            if(response.status===200 || response.status===201){
                console.log(response.data.data);
                setTableData(response.data.data);
                setCurrentPage(response.data.currentPage);
                setMaxPage(response.data.totalPages);
                setWorkLogStatus({
                    done:response.data.doneCount,
                    notDone:response.data.notDoneCount
                })
            }
        }catch(error:any){
            if(error.response.status===401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            if(error.response.status===404){
                showError('No Data Found!');
                return
            }
            showError('No Data found!')
            console.log(error);
        }
    }

    /* useEffect(()=>{
        console.log(page:currentPage, date:dateString, search:filterData, status:filterButtonValue)
        console.log('called here')
        filterSearch({page:currentPage, date:dateString, search:filterData, status:filterButtonValue});
    },[currentPage,dateString,filterButtonValue]) */
    useEffect(() => {
        console.log({
            page: currentPage, 
            date: dateString, 
            search: filterData, 
            status: filterButtonValue,
            cleaner:cleaner,
            society:society
        });
        console.log('called here');
        filterSearch({
            page: currentPage, 
            date: dateString, 
            search: filterData, 
            status: filterButtonValue,
            cleaner:cleaner,
            society:society
        });
    }, [currentPage, cleaner, society, dateString, filterButtonValue]);
    

    useEffect(()=>{
        setCurrentPage(1);
        filterButton.not_done ? setFilterButtonValue('not_done') : setFilterButtonValue(undefined);
    },[filterButton.not_done]);

    useEffect(()=>{
        setCurrentPage(1);
        filterButton.done ? setFilterButtonValue('done') : setFilterButtonValue(undefined);
    },[filterButton.done])

    useEffect(()=>{
        !filterData && filterSearch({page:1});
    },[filterData]);


    /*  useEffect(()=>{
        !filterData && getTableData(1);
    },[filterData]) */

    return (
        <div className={styles.work_logs}>
            <Toaster position="top-right" />
            <div className={styles.searchContainer}>
                <div style={{display:"flex", gap:"1rem", justifyContent:"space-between", width:"70rem" }}>
                    <TextInput onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter' && filterData) {
                            filterSearch({page:1, date:dateString, search:filterData, status:filterButtonValue, society:society, cleaner:cleaner});
                        }
                    }} onValueChange={(value)=>setFilterData(value)} className={styles.search} icon={RiSearchLine} placeholder="Search by name, flat number or mobile number" />

                    <DatePicker className={styles.calender} selectedDate={selectedDate} onChange={handleDateChange} />
                    <SearchSelect onValueChange={(value)=>{
                        console.log(value);
                        setCleaner(value);
                        /* filterSearch({page:1, date:dateString, search:filterData, status:filterButtonValue, }); */
                    }} className={styles.selecter} placeholder='Search cleaner'>
                        {
                            cleanerList?.map(cleaners=>(
                                <SearchSelectItem value={cleaners}>{cleaners}</SearchSelectItem>
                            ))
                        }
                    </SearchSelect>
                    <SearchSelect onValueChange={(value)=>{
                        console.log(value);
                        setSociety(value);
                        /* filterSearch({page:1, date:dateString, search:filterData, status:filterButtonValue, }); */
                    }} className={styles.selecter} placeholder='Search society'>
                    {
                            soceityList?.map(societys=>(
                                <SearchSelectItem value={societys}>{societys}</SearchSelectItem>
                            ))
                        }
                    </SearchSelect>


                </div>
                {/* <div style={{maxWidth:"20rem"}}>
                    <SearchSelect >
                        {
                            uniqueSocieties?.map((society, index) => (
                                <SearchSelectItem key={index} value={society}>
                                    {society}
                                </SearchSelectItem>
                            ))
                        }
                    </SearchSelect>
                </div> */}
                <Button /* disabled={!filterData} */ onClick={()=>filterSearch({
                                                                        page: currentPage, 
                                                                        date: dateString, 
                                                                        search: filterData, 
                                                                        status: filterButtonValue,
                                                                        cleaner:cleaner,
                                                                        society:society
                                                                    })} variant="secondary" icon={RiSearch2Line}>
                    Search
                </Button>
            </div>
            <div className={styles.searchContainer}>
                <div style={{display:"flex", marginTop:"2rem", justifyContent:"space-between",minWidth:"17rem"}}>
                    <Button onClick={()=>{
                        setFilterButton({
                            done:!filterButton.done,
                            not_done:false
                        })
                    }} icon={HiOutlineFilter} variant={filterButton.done ? 'primary' : 'secondary'} color='green'>completed</Button>
                    <Button onClick={()=>{
                        setFilterButton({
                            done:false,
                            not_done:!filterButton.not_done
                        })
                    }} icon={HiOutlineFilter} variant={filterButton.not_done ? 'primary' : 'secondary'}  color='blue'>Not Done</Button>
                </div>
            </div>
            <div className={styles.table}>
                <Title style={{marginBottom:"1rem"}}>Work Done: <span style={{color:"green"}}>{workLogStaus.done}</span>, Pending: <span style={{color:'red'}}>{workLogStaus.notDone}</span></Title>
                <Table className={styles.tableContainer} style={{width:"100%"}}>
                <TableHead >
                        <TableRow style={{borderRadius:"20px 20px 0px 0px", background: "#EAE8E8"}}>
                            <TableHeaderCell>Address</TableHeaderCell>
                            <TableHeaderCell>Spot No</TableHeaderCell>
                            <TableHeaderCell className="text-center">Clear Name</TableHeaderCell>
                            <TableHeaderCell className="text-center">Time</TableHeaderCell>
                            <TableHeaderCell className="text-center">Car Number</TableHeaderCell>
                            <TableHeaderCell className="text-center">Date</TableHeaderCell>
                            <TableHeaderCell className="text-center">Status</TableHeaderCell>
                            <TableHeaderCell className="text-left">Photo</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={styles.tableBody} style={{background:'#FFF',width:'100%'}}>
                        {
                            tableData.length!=0 ?
                                tableData?.map((item)=>(
                                    <TableRow key={item.cleaner_id}>
                                        <TableCell className={styles.tableCell}>{item?.address?.society}, {item?.address?.locality}</TableCell>
                                        <TableCell className={styles.tableCell}>{item?.parking?.spot_number}</TableCell>
                                        <TableCell style={{textAlign:"center"}} className={styles.tableCell}>{item?.cleaner_name}</TableCell>
                                        <TableCell style={{textAlign:"center"}} className={styles.tableCell}>{item?.timming}</TableCell>
                                        <TableCell style={{textAlign:"center"}} className={styles.tableCell}>{item?.car_number}</TableCell>
                                        <TableCell style={{textAlign:"center"}} className={styles.tableCell}>{item?.assigned_date}</TableCell>
                                        <TableCell style={{textAlign:"center"}} >
                                            <Badge style={{width:"120px", fontSize:"12px"}} color={item.work_status=='done' ? 'green' : `${item.work_status=='waiting' ? 'blue': 'red'}`} >{item?.work_status=='done' ? 'complete' : `${item?.work_status=='waiting' ? 'yet to be done': 'missed out'}`}</Badge>
                                        </TableCell>
                                        <TableCell style={{alignItems:"center", justifyContent:'center', display:'flex', flexDirection:"row", gap:"10px", padding:"0", cursor:"pointer"}}>
                                        {
                                            item?.uploads.map(imagesData=>(
                                                    <img onClick={()=>{imagesData?.s3_url!= "" && setImageView(true); imagesData?.s3_url!= "" && setImageLink(imagesData?.s3_url!= "" ? imagesData?.s3_url :'')}} style={{height:'35px'}} src={ imagesData?.s3_url_thumbnail} alt='car'/>
                                            ))
                                        }
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow >
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}>No Data</TableCell>
                                    <TableCell style={{textAlign:"center"}} className={styles.tableCell}></TableCell>
                                    <TableCell style={{cursor:"pointer"}} >
                                    </TableCell>
                                    <TableCell onClick={()=>setDeleteView(true)} style={{alignItems:"right", cursor:"pointer"}}>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}></TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
                <div style={{width:"100%", display:"flex", marginTop:"18px", justifyContent:"end"}}>
                    <p style={{fontSize:"16px"}}>Page {currentPage} of {maxPage}</p>
                </div>
            </div>
            <div className={styles.buttonContainer} >
                <div className={styles.miniContainer}>
                </div>
                <div className={styles.miniContainer}>
                    <Button onClick={()=> filterSearch({
                        page: currentPage===1 ? 1 :currentPage-1, 
                        date: dateString, 
                        search: filterData, 
                        status: filterButtonValue,
                        cleaner:cleaner,
                        society:society
                    })} variant='secondary'  icon={FaArrowAltCircleLeft}>Prev</Button>
                    <Button onClick={()=> filterSearch({
                        page: currentPage>=maxPage ? maxPage : currentPage+1,
                        date: dateString, 
                        search: filterData, 
                        status: filterButtonValue,
                        cleaner:cleaner,
                        society:society
                    })} variant='secondary' iconPosition='right' icon={FaArrowAltCircleRight}>Next</Button>
                </div>
            </div>
            {
                imageView &&
                <Card className={styles.imageView}>
                    <img src={imageLink} />
                    <div style={{height:'20px'}}></div>
                    <Button onClick={()=>{setImageView(false);setImageLink('')}} color='red'>Close</Button>
                </Card>
            }
        </div>
    )
}

export default Work_Logs