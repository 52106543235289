import { RiSearch2Line, RiSearchLine } from '@remixicon/react';
import { Button, Card, Table, TableBody, TableHeaderCell, TextInput, Title, TableCell, TableHead, TableRow, NumberInput } from '@tremor/react';
import styles from './Users.module.scss';
import data from './UserData.json';
import { useEffect, useState } from 'react';
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import axios from 'axios';
import { MdOutlineUploadFile } from "react-icons/md";
import * as XLSX from "xlsx";
import { FaFileDownload } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';
import { duration } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setAuth, setLogout } from '../../app/redux_slices/auth_slice';
import { useAuthHeaders } from '../shared/header';
import { baseUrl } from '../shared/shared';



interface Data{
    name:string
    flat_no:string
    address:string
    mobile_number:string
    registration_no:string
    model:string
    parking:string
}

interface addIndividualData{
    name:string,
    mobile_number:string,
    email:string,
    flat_number:string,
    society:string,
    locality:string,
    floor:string,
    spot_number:string,
    model:string,
    car_number:string,
    make_OEM:string
}

const initialData:addIndividualData = {
    car_number:"",
    email:"",
    flat_number:"",
    floor:"",
    locality:"",
    make_OEM:"",
    mobile_number:"",
    model:"",
    name:"",
    society:"",
    spot_number:""
}

interface UploadIndividualFile{
    name:string,
    mobile_number:string,
    email:string,
    flat_number:string,
    society:string,
    locality:string,
    parking:{
        floor:string,
        spot_number:string
    },
    car_details:{
        model:string,
        car_number:string,
        make_OEM:string
    },
    created_by:{
        type:string,
        uploaded_file_url:string
    }
}

interface TableData {
    _id: string
    name: string
    mobile_number: string
    email: string
    address: Address
    amount: number
    parking: Parking
    car_details: CarDetails
    created_by: CreatedBy
    is_active: boolean
    is_deleted: boolean
    updated_by: string
    createdAt: string
    updatedAt: string
    __v: number
  }
  
  export interface Address {
    locality: string
    city: string
    state: string
    flat_number: string
    block_name: string
    society:string
  }
  
  export interface Parking {
    floor: string
    spot_number: string
  }
  
  export interface CarDetails {
    model: string
    car_number: string
    make_OEM: string
  }
  
  export interface CreatedBy {
    type: string
    uploaded_file_url: string
  }
  

/* interface TableData{
    _id:string,
    is_active:boolean,
    is_deleted:boolean
    name:string,
    mobile_number:string,
    email:string,
    flat_number:string,
    society:string,
    locality:string,
    parking:{
        floor:string,
        spot_number:string
    },
    car_details:{
        model:string,
        car_number:string,
        make_OEM:string
    },
    created_by:{
        type:string,
        uploaded_file_url:string
    }
} */

interface ErrorLog {
    car_number: boolean
    mobile_number: boolean
    email: boolean
    name: boolean
    flat_number: boolean
    society: boolean
    locality: boolean
    floor: boolean
    spot_number: boolean
    model: boolean
    make_OEM: boolean
    type: boolean
    uploaded_file_url: boolean
    updated_by: boolean
}

const intialStateErrorLog: ErrorLog = {
    car_number: false,
    mobile_number: false,
    name: false,
    model: false,
    make_OEM: false,
    floor: false,
    spot_number: false,
    type: false,
    uploaded_file_url: false,
    updated_by: false,
    email:false,
    flat_number:false,
    locality:false,
    society:false
};

export default function Users() {

    const { data_header, file_header } = useAuthHeaders();

    const dispatch = useAppDispatch()
    const auth = useAppSelector(state => state.authSlice.auth)

    const FileUnderReview = () => toast.loading('File Uploaded to server, Under review kindly weight',{duration:1500});
    const uploaded = ()=>toast.success('File Uploaded Successfully!');
    const errorInFile = () => toast.error('Uploaded File has corrupt/wrong data please download it and recify the mistake and reupload it again');
    const errorInFileUpload = (errorValue:string) => toast.error(`Error uploading file, ${errorValue}`);
    const unexpectedError = () => toast.error('An Unexpected error occurred please retry!');
    const showError = (errorStatement:string) =>toast.error(errorStatement);

    const [uploadFileView, setUploadFileView]=useState(false);
    const [addFileView, setAddFileView]=useState(false);
    const [deleteView,setDeleteView]=useState(false);
    const [loading, setLoading]=useState(false);

    const [csvBlob, setCsvBlob] = useState<Blob | null>(null);
    const [errorNumber, setErrorNumber]=useState<number |  null>();
    const [errorLog, setErrorLog]=useState<ErrorLog>(intialStateErrorLog);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>("Add File");

    const [individualData, setIndividualData]=useState<addIndividualData>(initialData)
    const [tableData, setTableData]=useState<TableData[] | []>([]);
    const [currentPage, setCurrentPage]=useState<number>(1);
    const [maxPage, setMaxPage]=useState<number>(100);
    const [filterData, setFilterData]=useState<string | null>();
    const [update, setUpdate]=useState<boolean>(false)
    const [updateId, setUpdateId]=useState<string>("");
    const [userName, setUserName]=useState("");

    async function UploadExcelCheck(id:string){
        try{
            const response = await axios.get(`${baseUrl}/api/v1/ops-admin/customers/poll-job-result?job_id=${id}`,
                data_header
            );
            const jobStatus = response.headers['job_status'];
            if(response.status==200 || response.status==201){
                console.log(response.headers.get)
                console.log(jobStatus);
                if(jobStatus=='Processing'){
                    UploadExcelCheck(id);
                    setLoading(true);
                }
                else{
                    if(jobStatus=='Completed'){
                        setUploadFileView(false);
                        setLoading(false);
                        uploaded();
                        setSelectedFile(null);
                        setCsvBlob(null);
                        setFileName("Add File");
                    }
                    else if(jobStatus=='Failed'){
                        setErrorNumber(response.headers['no_records_with_error']);
                        const blob = new Blob([response.data], { type: 'text/csv' });
                        setCsvBlob(blob);
                        setLoading(false);
                        errorInFile()
                    }
                }
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
            }
            unexpectedError();
            console.log(error);
            //error handling here
        }
    }

    async function filterSearch(key:string, page:number){
        setTableData([]);
        try{
            const response = await axios.get(`${baseUrl}/api/v1/ops-admin/customers/get-customers-by-param?param=${key}&page=${page}&limit=12`, data_header);
            if(response.status==200 || response.status==201){
                console.log(response.data);
                setTableData(response.data.customers);
                setCurrentPage(response.data.currentPage);
                setMaxPage(response.data.totalPages);
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            showError('No Data found!')
            console.log(error);
        }
    }

    useEffect(()=>{
        !filterData && getTableData(1);
    },[filterData])

    async function UploadExcel() {
        if (!selectedFile) {
            console.log("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const response = await axios.post(
                `${baseUrl}/api/v1/ops-admin/customers/upload-csv`,
                formData,
                file_header
            );
            if(response.status==200 || response.status==201){
                UploadExcelCheck(response.data.job_id);
                FileUnderReview();
                getTableData(1);
            }
            console.log("Upload successful:", response.status);
            console.log("Response data:", response.data);
        } catch (error: any) {
            if(error.response.status==401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            showError('Failed to Upload the file!');
            console.error("Error uploading file:", error);
        }
    }

    useEffect(()=>{
        getTableData(1);
    },[]);

    async function getTableData(page:number){
        console.log(page);
        setTableData([]);
        try{
            const response = await axios.get(`${baseUrl}/api/v1/ops-admin/customers/get-customers?page=${page}&limit=12`, data_header);
            if(response.status==200 || response.status==201){
                console.log(response.data);
                setTableData(response.data.customers);
                setCurrentPage(response.data.page);
                setMaxPage(response.data.totalPages);
            }
        }catch(error:any){
            console.log(error.status)
            if(error.response.status==401){
                dispatch(setLogout())
            }
            if(error.response.status==404){
                showError('No Data Found!');
                return
            }
            showError('Failed to Load the table data!');
        }
    }

    async function uploadIndividualFile(file:UploadIndividualFile){
        try{
            const response = await axios.post(`${baseUrl}/api/v1/ops-admin/customers/create`,file,data_header);
            if(response.status==200 || response.status==201){
                console.log(file)
                uploaded();
                setAddFileView(false);
                setIndividualData(initialData);
                getTableData(1);
                setErrorLog(intialStateErrorLog)
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
            }
            console.error(error.response);
            errorInFileUpload(error.response.data.data);
            setErrorLog(error.response.data.error_columns);
        }
    }

    async function uploadUpdateFile(file:UploadIndividualFile){
        try{
            const response = await axios.put(`${baseUrl}/api/v1/ops-admin/customers/update-customer?id=${updateId}`,file,data_header);
            if(response.status==200 || response.status==201){
                console.log(file)
                uploaded();
                setAddFileView(false);
                setIndividualData(initialData);
                getTableData(1);
                setUpdateId("");
                setUpdate(false);
                setErrorLog(intialStateErrorLog)
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
            }
            console.error(error.response);
            errorInFileUpload(error.response.data.data);
            setErrorLog(error.response.data.error_columns);
        }
    }

    const downloadCSV = () => {
        setLoading(false);
        if (!csvBlob) {
            console.log("No CSV file available to download");
            return;
        }

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(csvBlob);
        link.download = 'user_data.csv';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    };

    // change any format excel file into csv
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLoading(false);
        setCsvBlob(null);
        setErrorNumber(null);
        const files = event.target.files;
        if (!files) return;
        Array.from(files).forEach(file => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            if (fileExtension === 'csv') {
                setSelectedFile(file);
                setFileName(file.name);
            } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    const data = new Uint8Array(e.target?.result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const csv = XLSX.utils.sheet_to_csv(worksheet);

                    const csvBlob = new Blob([csv], { type: 'text/csv' });
                    const csvFileName = file.name.replace(/\.(xls|xlsx)$/, '.csv');
                    const csvFile = new File([csvBlob], csvFileName, {
                        type: 'text/csv',
                    });

                    setSelectedFile(csvFile);
                    setFileName(csvFileName);
                };
                reader.readAsArrayBuffer(file);
            } else {
                console.log("Unsupported file type");
            }
        });
    };

    async function DeleteFile(id:string){
        try{
            const response = await axios.delete(`${baseUrl}/api/v1/ops-admin/customers/delete-customer?id=${id}`,data_header);
            if(response.status==200 || response.status==201){
                getTableData(1);
                setUpdateId("");
                setDeleteView(false);
                setUserName("");
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
            }
            console.error(error.response);
            showError('Error Deleting the file!');
        }
    }

    return (
        <div className={styles.users}>
            <Toaster position="top-right" />
            <div className={styles.searchContainer}>
                <TextInput onValueChange={(value)=>setFilterData(value)} onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter' && filterData) {
                        filterSearch(filterData, 1);
                    }
                }} className={styles.search} icon={RiSearchLine} placeholder="Search by name, flat number or mobile number" />
                <Button disabled={!filterData} onClick={()=>filterData && filterSearch(filterData, 1)} variant="secondary" icon={RiSearch2Line}>
                    Search
                </Button>
            </div>
            <div className={styles.table}>
                <Table className={styles.tableContainer} style={{width:"100%"}}>
                <TableHead style={{borderRadius:"20px 20px 0px 0px", background: "#EAE8E8"}}>
                        <TableRow>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Flat No</TableHeaderCell>
                            <TableHeaderCell>Address</TableHeaderCell>
                            <TableHeaderCell>Mobile Number</TableHeaderCell>
                            <TableHeaderCell>Registration No.</TableHeaderCell>
                            <TableHeaderCell>Model</TableHeaderCell>
                            <TableHeaderCell className="text-center">Parking</TableHeaderCell>
                            <TableHeaderCell className="text-right">Edit</TableHeaderCell>
                            <TableHeaderCell className="text-right">Delete</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={styles.tableBody} style={{background:'#FFF',width:'100%'}}>
                        {
                            tableData.length!=0 ?
                            tableData?.map((item:TableData, index:number)=>(
                                <TableRow key={item?.name}>
                                    <TableCell className={styles.tableCell}>{item?.name}</TableCell>
                                    <TableCell className={styles.tableCell}>{item?.address?.flat_number}</TableCell>
                                    <TableCell className={styles.tableCell}>{item?.address?.society} {item?.address?.block_name} {item?.address?.locality}</TableCell>
                                    <TableCell className={styles.tableCell}>{item?.mobile_number}</TableCell>
                                    <TableCell className={styles.tableCell}>{item?.car_details?.car_number}</TableCell>
                                    <TableCell className={styles.tableCell}>{item?.car_details?.model}</TableCell>
                                    <TableCell style={{textAlign:"center"}} className={styles.tableCell}>{item?.parking?.floor} {item?.parking?.spot_number}</TableCell>
                                    <TableCell style={{cursor:"pointer"}} onClick={()=>{setUpdate(true);
                                    setUpdateId(item?._id);
                                        setIndividualData({
                                            name:item?.name,
                                            car_number:item?.car_details?.car_number,
                                            email:item?.email,
                                            flat_number:item?.address?.flat_number,
                                            floor:item?.parking?.floor,
                                            locality:item?.address.locality,
                                            spot_number:item?.parking?.spot_number,
                                            make_OEM:item?.car_details?.make_OEM,
                                            mobile_number:item?.mobile_number,
                                            model:item?.car_details?.model,
                                            society:item?.address.society
                                        }); setAddFileView(true)}} ><div style={{display:"flex", justifyContent:"right", width:"100%"}}><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4205 3.3295C14.9811 2.89016 14.2688 2.89016 13.8295 3.3295L13.2348 3.92417L14.8258 5.51516L15.4205 4.92049C15.8598 4.48115 15.8598 3.76884 15.4205 3.3295ZM16.4811 5.98115C17.5062 4.95603 17.5062 3.29397 16.4811 2.26884C15.456 1.24372 13.7939 1.24372 12.7688 2.26884L11.6439 3.3937L2.46967 12.568C2.32902 12.7086 2.25 12.8994 2.25 13.0983V15.7766C2.25 16.1908 2.58579 16.5266 3 16.5266H5.625C5.82391 16.5266 6.01468 16.4476 6.15533 16.3069L16.4811 5.98115ZM13.7651 6.57582L12.1741 4.98483L3.75 13.409V15.0266H5.31434L13.7651 6.57582Z" fill="#111827"/></svg></div>
                                    </TableCell>
                                    <TableCell onClick={()=>{setDeleteView(true); setUpdateId(item?._id); setUserName(`${item?.name}, ${item?.car_details?.car_number}`)}} style={{alignItems:"right", cursor:"pointer"}}><div style={{display:"flex", justifyContent:"right", width:"100%"}} ><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 3C6 2.17157 6.67157 1.5 7.5 1.5H10.5C11.3284 1.5 12 2.17157 12 3V4.5H14.2423C14.2469 4.49996 14.2515 4.49996 14.2562 4.5H15C15.4142 4.5 15.75 4.83579 15.75 5.25C15.75 5.66421 15.4142 6 15 6H14.9483L14.3476 14.4103C14.2635 15.5877 13.2838 16.5 12.1033 16.5H5.89668C4.71624 16.5 3.7365 15.5877 3.6524 14.4103L3.05166 6H3C2.58579 6 2.25 5.66421 2.25 5.25C2.25 4.83579 2.58579 4.5 3 4.5H3.74381C3.74846 4.49996 3.75311 4.49996 3.75774 4.5H6V3ZM4.55548 6L5.14858 14.3034C5.17662 14.6959 5.5032 15 5.89668 15H12.1033C12.4968 15 12.8234 14.6959 12.8514 14.3034L13.4445 6H4.55548ZM10.5 4.5H7.5V3H10.5V4.5ZM7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5ZM10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#111827"/></svg></div>
                                    </TableCell>
                                </TableRow>
                            ))
                            :
                                <TableRow >
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}>No Data</TableCell>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell style={{textAlign:"center"}} className={styles.tableCell}></TableCell>
                                    <TableCell style={{cursor:"pointer"}} >
                                    </TableCell>
                                    <TableCell onClick={()=>setDeleteView(true)} style={{alignItems:"right", cursor:"pointer"}}>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
                <div style={{width:"100%", display:"flex", marginTop:"18px", justifyContent:"end"}}>
                    <p style={{fontSize:"16px"}}>Page {currentPage} of {maxPage}</p>
                </div>
            </div>
            <div className={styles.buttonContainer} >
                <div className={styles.miniContainer}>
                    <Button variant='secondary' onClick={()=>setUploadFileView(true)}>Upload Records</Button>
                    <Button variant='secondary' onClick={()=>setAddFileView(true)}>Add</Button>
                </div>
                <div className={styles.miniContainer}>
                <Button onClick={()=>!filterData ? getTableData(currentPage===1 ? 1 :currentPage-1): filterSearch(filterData,currentPage===1 ? 1 :currentPage-1 )} variant='secondary'  icon={FaArrowAltCircleLeft}>Prev</Button>
                <Button onClick={()=> !filterData ? getTableData(currentPage===maxPage ? maxPage : currentPage+1) : filterSearch(filterData,currentPage===maxPage ? maxPage : currentPage+1)} variant='secondary' iconPosition='right' icon={FaArrowAltCircleRight}>Next</Button>
                </div>
            </div>
            {
                uploadFileView &&
                <div className={styles.uploadContainer}>
                    <Card className={styles.card}>
                        <Title>Please add excel file with columns in this order</Title>
                        <ul>
                            <li>Name</li>
                            <li>Flat No</li>
                            <li>Address</li>
                            <li>Mobile Number</li>
                            <li>Registration Number</li>
                            <li>Model</li>
                            <li>Parking</li>
                        </ul>
                        <div className={styles.buttonUploadContainer}>
                            <div className={styles.inputContainer}>
                                <p >{fileName}</p>
                                <MdOutlineUploadFile className={styles.uploadLogo} />
                                <input
                                    type="file"
                                    accept=".csv,.xls,.xlsx"
                                    onChange={handleFileChange}
                                />
                            </div>
                            {
                                errorNumber &&
                                <p style={{color:'red', maxWidth:"350px", textAlign:'center'}}>* {errorNumber} records were corrupt/wrong please download it and recify the mistake and reupload it again</p>
                            }
                            {
                                csvBlob &&
                                <Button color='green' icon={FaFileDownload} onClick={downloadCSV}>Download CSV</Button>
                            }
                            {
                                selectedFile &&
                                <Button onClick={()=>{UploadExcel();}} loading={loading}>Upload</Button>
                            }
                            <Button color='red' onClick={()=>{setUploadFileView(false); setLoading(false);
                                        setSelectedFile(null);
                                        setCsvBlob(null);
                                        setErrorNumber(null);
                                        setFileName("Add File");}} loading={loading}>Cancel Action</Button>
                        </div>
                    </Card>
                </div>
            }
            {
                addFileView &&
                <div className={styles.uploadContainer}>
                    <Card className={styles.card2}>
                        <div className={styles.inputContainer}>
                            <div className={styles.inputs}>
                                <p>Name and Contact</p>
                                <div className={styles.smallContainer}>
                                    <div className={styles.smallContainerTop}>
                                        <TextInput error={errorLog?.name} value={individualData.name} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                name:value
                                            }
                                        ))} placeholder='Enter Customer Name' className={styles.input} />
                                        <TextInput error={errorLog?.mobile_number} value={individualData.mobile_number} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                mobile_number:value
                                            }
                                        ))} placeholder='Enter Business Number' className={styles.input} />
                                    </div>
                                    <TextInput error={errorLog?.email} value={individualData.email} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                email:value
                                            }
                                        ))} placeholder='Enter Business Email' className={styles.input} type='email' />
                                </div>
                            </div>
                            <div className={styles.inputs}>
                                <p>Address</p>
                                <div className={styles.smallContainer}>
                                    <div className={styles.smallContainerTop}>
                                        <TextInput error={errorLog?.flat_number} value={individualData.flat_number} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                flat_number:value
                                            }
                                        ))} placeholder='Enter Flat Number' className={styles.input} />
                                        <TextInput error={errorLog?.society} value={individualData.society} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                society:value
                                            }
                                        ))} placeholder='Enter Society Name' className={styles.input} />
                                    </div>
                                    <div className={styles.smallContainerTop}>
                                        <TextInput error={errorLog?.locality} value={individualData.locality} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                locality:value
                                            }
                                        ))} placeholder='Enter Locality' className={styles.input} />
                                        <TextInput error={errorLog?.floor} value={individualData.floor} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                floor:value
                                            }
                                        ))} placeholder='Enter Parking Floor' className={styles.input} />
                                    </div>
                                    <TextInput error={errorLog?.spot_number} value={individualData.spot_number} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                spot_number:value
                                            }
                                        ))} placeholder='Enter Spot Number' className={styles.input} />
                                </div>
                            </div>
                            <div className={styles.inputs}>
                                <p>Car Details</p>
                                <div className={styles.smallContainer}>
                                    <div className={styles.smallContainerTop}>
                                        <TextInput error={errorLog?.model} value={individualData.model} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                model:value
                                            }
                                        ))} placeholder='Enter Model' className={styles.input} />
                                        <TextInput error={errorLog?.car_number} value={individualData.car_number} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                car_number:value
                                            }
                                        ))} placeholder='Enter Car Number' className={styles.input} />
                                    </div>
                                    <TextInput error={errorLog?.make_OEM} value={individualData.make_OEM} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                make_OEM:value
                                            }
                                        ))} placeholder='Enter OEM' className={styles.input} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttonContainer}>
                            <div></div>
                            <div className={styles.miniContainer}>
                                <Button onClick={()=>{setAddFileView(false); setIndividualData(initialData); setErrorLog(intialStateErrorLog)}} variant='secondary'>Cancel</Button>
                                <Button onClick={()=>
                                    update ?
                                    uploadUpdateFile({
                                        name: individualData.name,//0 as unknown as string,
                                        mobile_number:individualData.mobile_number,
                                        email:individualData.email,
                                        flat_number:individualData.flat_number,
                                        society:individualData.society,
                                        locality:individualData.locality,
                                        parking:{
                                            floor:individualData.floor,
                                            spot_number:individualData.spot_number
                                        },
                                        car_details:{
                                            model:individualData.model,
                                            car_number:individualData.car_number,
                                            make_OEM:individualData.make_OEM
                                        },
                                        created_by:{
                                            type:"admin",
                                            uploaded_file_url:""
                                        }
                                    })
                                    :
                                    uploadIndividualFile({
                                        name: individualData.name,//0 as unknown as string,
                                        mobile_number:individualData.mobile_number,
                                        email:individualData.email,
                                        flat_number:individualData.flat_number,
                                        society:individualData.society,
                                        locality:individualData.locality,
                                        parking:{
                                            floor:individualData.floor,
                                            spot_number:individualData.spot_number
                                        },
                                        car_details:{
                                            model:individualData.model,
                                            car_number:individualData.car_number,
                                            make_OEM:individualData.make_OEM
                                        },
                                        created_by:{
                                            type:"admin",
                                            uploaded_file_url:""
                                        }
                                    })}>Add</Button>
                            </div>
                        </div>
                    </Card>
                </div>
            }
            {
                deleteView &&
                <div className={styles.uploadContainer}>
                    <Card className={styles.deleteCard}>
                        <div className={styles.detailSection}>
                            <p>Are you sure you want to delete record of</p>
                            <Title style={{color:"black"}}>{userName}</Title>
                        </div>
                        <div className={styles.deleteButtons}>
                            <Button style={{fontSize:"14px"}} onClick={()=>{setDeleteView(false)}} variant='secondary'>Cancel</Button>
                            <Button style={{fontSize:"14px"}} onClick={()=>{DeleteFile(updateId)}} color='red'>Delete</Button>
                        </div>
                    </Card>
                </div>
            }
        </div>
    )
}
