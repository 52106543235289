import { Button, Card, Table, TableBody, TableHeaderCell, TextInput, Title, TableCell, TableHead, TableRow } from '@tremor/react';
import styles from './Cleaners.module.scss';
import { RiSearch2Line, RiSearchLine } from '@remixicon/react';
import data from './cleanerData.json'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaFileDownload } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setAuth, setLogout } from '../../app/redux_slices/auth_slice';
import { useAuthHeaders } from '../shared/header';
import { baseUrl } from '../shared/shared';
import * as XLSX from "xlsx";
import { MdOutlineUploadFile } from 'react-icons/md';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { BorderColor, Password } from '@mui/icons-material';
import { MdError } from "react-icons/md";


interface addIndividualData{
    name:string,
    mobile_number:string,
    aadhaar_number:string,
    timming:string,
    car_number:string,
}

const initialData:addIndividualData = {
    name:"",
    aadhaar_number:"",
    mobile_number:"",
    car_number:"",
    timming:"",
}

interface UploadIndividualFile{
    name:string
    aadhaar_number:string
    mobile_number:string
    car_number:string
    timming:string
    created_by:{
        type:string
        uploaded_file_url:string
    }
    updated_by:string
}

interface TableData{
    _id:string
    name:string
    aadhaar_number:string
    mobile_number:string
    car_number:string
    timming:string
    parking:{
        floor:string
        spot_number:string
    }
    created_by:{
        type:string
        uploaded_file_url:string
    },
    address: {
        society: string,
        locality: string
    },
    is_active:boolean
    is_deleted:boolean

}

interface ErrorLog {
    car_number: boolean
    mobile_number: boolean
    aadhaar_number: boolean
    name: boolean
    model: boolean
    make_OEM: boolean
    timming: boolean
    password: boolean
    floor: boolean
    spot_number: boolean
    type: boolean
    uploaded_file_url: boolean
    updated_by: boolean
}

const intialStateErrorLog: ErrorLog = {
    car_number: false,
    mobile_number: false,
    aadhaar_number: false,
    name: false,
    model: false,
    make_OEM: false,
    timming: false,
    password: false,
    floor: false,
    spot_number: false,
    type: false,
    uploaded_file_url: false,
    updated_by: false
};

interface Clean{
    page:number,
    search?:undefined| string
}


export default function Cleaners() {
    const [uploadFileView, setUploadFileView]=useState(false);
    const [addFileView, setAddFileView]=useState(false);
    const [deleteView,setDeleteView]=useState(false);
    const [loading, setLoading]=useState(false);

    const [csvBlob, setCsvBlob] = useState<Blob | null>(null);
    const [errorNumber, setErrorNumber]=useState<number |  null>();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>("Add File");
    const [userName, setUserName]=useState("");

    const { data_header, file_header } = useAuthHeaders();

    const dispatch = useAppDispatch()
    const auth = useAppSelector(state => state.authSlice.auth)

    const [individualData, setIndividualData]=useState<addIndividualData>(initialData)
    const [tableData, setTableData]=useState<TableData[] | []>([]);
    const [currentPage, setCurrentPage]=useState<number>(1);
    const [maxPage, setMaxPage]=useState<number>(100);
    const [filterData, setFilterData]=useState<string | undefined>();
    const [update, setUpdate]=useState<boolean>(false);
    const [updateId, setUpdateId]=useState<string>("");
    const [startTime, setStartTime] = useState<Dayjs | undefined>( );
    const [endTime, setEndTime] = useState<Dayjs | undefined>();
    const [errorLog, setErrorLog]=useState<ErrorLog>(intialStateErrorLog);

    const FileUnderReview = () => toast.loading('File Uploaded to server, Under review kindly weight',{duration:1500});
    const uploaded = ()=>toast.success('File Uploaded Successfully!');
    const errorInFile = () => toast.error('Uploaded File has corrupt/wrong data please download it and recify the mistake and reupload it again');
    const errorInFileUpload = (errorValue:string) => toast.error(`Error uploading file, ${errorValue}`);
    const unexpectedError = () => toast.error('An Unexpected error occurred please retry!');
    const showError = (errorStatement:string) =>toast.error(errorStatement);

    // to download the file in csv
    const downloadCSV = () => {
        setLoading(false);
        if (!csvBlob) {
            console.log("No CSV file available to download");
            return;
        }

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(csvBlob);
        link.download = 'user_data.csv';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    };

    // change any format excel file into csv
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLoading(false);
        setCsvBlob(null);
        setErrorNumber(null);
        const files = event.target.files;
        if (!files) return;
        Array.from(files).forEach(file => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            if (fileExtension === 'csv') {
                setSelectedFile(file);
                setFileName(file.name);
            } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    const data = new Uint8Array(e.target?.result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const csv = XLSX.utils.sheet_to_csv(worksheet);

                    const csvBlob = new Blob([csv], { type: 'text/csv' });
                    const csvFileName = file.name.replace(/\.(xls|xlsx)$/, '.csv');
                    const csvFile = new File([csvBlob], csvFileName, {
                        type: 'text/csv',
                    });

                    setSelectedFile(csvFile);
                    setFileName(csvFileName);
                };
                reader.readAsArrayBuffer(file);
            } else {
                console.log("Unsupported file type");
            }
        });
    };

    async function UploadExcelCheck(id:string){
        try{
            const response = await axios.get(`${baseUrl}/api/v1/ops-admin/cleaners/poll-job-result?job_id=${id}`,
                data_header
            );
            const jobStatus = response.headers['job_status'];
            if(response.status===200 || response.status===201){
                //console.log(response.headers.get)
                console.log(response.headers);
                if(jobStatus==='Processing'){
                    UploadExcelCheck(id);
                    setLoading(true);
                }
                else{
                    if(jobStatus=='Completed'){
                        setUploadFileView(false);
                        setLoading(false);
                        uploaded();
                        setSelectedFile(null);
                        setCsvBlob(null);
                        setFileName("Add File");
                    }
                    else if(jobStatus=='Failed'){
                        setErrorNumber(response.headers['no_records_with_error']);
                        const blob = new Blob([response.data], { type: 'text/csv' });
                        setCsvBlob(blob);
                        setLoading(false);
                        errorInFile()
                    }
                }
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            unexpectedError();
            console.log(error);
            //error handling here
        }
    }

    async function UploadExcel() {
        if (!selectedFile) {
            console.log("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const response = await axios.post(
                `${baseUrl}/api/v1/ops-admin/cleaners/upload-csv`,  // change with cleaner
                formData,
                file_header
            );
            if(response.status===200 || response.status===201){
                UploadExcelCheck(response.data.job_id);
                FileUnderReview();
                filterSearch({page:1});
            }
            /* console.log("Upload successful:", response.status);
            console.log("Response data:", response.data); */
        } catch (error: any) {
            if(error.response.status==401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            showError('Failed to Upload the file!');
            console.error("Error uploading file:", error);
        }
    }

    useEffect(()=>{
        filterSearch({page:1});
    },[]);

    useEffect(()=>{
        !filterData && filterSearch({page:1});
    },[filterData]);

/*     async function getTableData(page:number){
        console.log(page);
        setTableData([]);
        try{
            const response = await axios.get(`${baseUrl}/api/v1/ops-admin/cleaners/get-cleaners?page=${page}&limit=12`, data_header); // need to change for the cleaner
            if(response.status==200 || response.status==201){
                console.log(response.data);
                setTableData(response.data.cleaners);
                setCurrentPage(response.data.currentPage);
                setMaxPage(response.data.totalPages);
            }
        }catch(error:any){
            console.log(error.status)
            if(error.response.status==401){
                dispatch(setLogout())
            }
            if(error.response.status==404){
                showError('No Data Found!');
                return
            }
            showError('Failed to Load the table data!');
        }
    } */

    async function uploadIndividualFile(file:UploadIndividualFile){
        try{
            const response = await axios.post(`${baseUrl}/api/v1/ops-admin/cleaners/create`,file,data_header);
            if(response.status==200 || response.status==201){
                console.log(file)
                uploaded();
                setAddFileView(false);
                setIndividualData(initialData);
                filterSearch({page:1});
                setEndTime(undefined)
                setStartTime(undefined)
                setErrorLog(intialStateErrorLog)
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            console.error(error.response);
            errorInFileUpload(error.response.data.data);
            setErrorLog(error.response.data.error_columns);
        }
    }

    async function uploadUpdateFile(file:UploadIndividualFile){
        try{
            const response = await axios.put(`${baseUrl}/api/v1/ops-admin/cleaners/update?id=${updateId}`,file,data_header);
            if(response.status==200 || response.status==201){
                console.log(file)
                uploaded();
                setAddFileView(false);
                setIndividualData(initialData);
                filterSearch({page:1});
                setUpdateId("");
                setUpdate(false);
                setEndTime(undefined);
                setStartTime(undefined);
                setErrorLog(intialStateErrorLog)
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            console.error(error.response);
            errorInFileUpload(error.response.data.data);
            setErrorLog(error.response.data.error_columns)
        }
    }

    async function DeleteFile(id:string){
        try{
            const response = await axios.delete(`${baseUrl}/api/v1/ops-admin/cleaners/delete?id=${id}`,data_header);
            if(response.status==200 || response.status==201){
                filterSearch({page:1});
                setUpdateId("");
                setDeleteView(false);
                setUserName("");
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            console.error(error.response);
            showError('Failed to delete the file!')
        }
    }

    async function filterSearch({page, search=undefined}: Clean){
        let url = `api/v1/ops-admin/cleaners/get-cleaners?page=${page}&limit=10`;
        setTableData([]);
        
        if (search) url += `&search=${encodeURIComponent(search)}`;
        console.log(url);
        try{
            const response = await axios.get(`${baseUrl}/${url}`, data_header);
            if(response.status==200 || response.status==201){
                console.log(response.data);
                setTableData(response.data.cleaners);
                setCurrentPage(response.data.currentPage);
                setMaxPage(response.data.totalPages);
            }
        }catch(error:any){
            if(error.response.status==401){
                dispatch(setLogout())
                showError('Session Expired!');
            }
            showError('No Data found!')
            console.log(error);
        }
    }

    return (
        <div className={styles.cleaners}>
            <Toaster position="top-right" />
            <div className={styles.searchContainer}>
                <TextInput onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter' && filterData) {
                        filterSearch({page:1, search:filterData});
                    }
                }} onValueChange={(value)=>setFilterData(value)} className={styles.search} icon={RiSearchLine} placeholder="Search by name, flat number or mobile number" />
                <Button disabled={!filterData} onClick={()=>filterData && filterSearch({page:1, search:filterData})} variant="secondary" icon={RiSearch2Line}>
                    Search
                </Button>
            </div>
            <div className={styles.table}>
                <Table className={styles.tableContainer} style={{width:"100%"}}>
                <TableHead style={{borderRadius:"20px 20px 0px 0px", background: "#EAE8E8"}}>
                        <TableRow>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Mobile Number</TableHeaderCell>
                            <TableHeaderCell>Car Number</TableHeaderCell>
                            <TableHeaderCell>Timing</TableHeaderCell>
                            <TableHeaderCell className="text-center">Parking Spot</TableHeaderCell>
                            <TableHeaderCell className="text-right">Edit</TableHeaderCell>
                            <TableHeaderCell className="text-right">Delete</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={styles.tableBody} style={{background:'#FFF',width:'100%'}}>
                        {
                            tableData.length!=0 ?
                            tableData?.map((item)=>(
                                <TableRow key={item.name}>
                                    <TableCell className={styles.tableCell}>{item?.name}</TableCell>
                                    <TableCell className={styles.tableCell}>{item?.mobile_number}</TableCell>
                                    <TableCell className={styles.tableCell}>{item?.car_number}</TableCell>
                                    <TableCell className={styles.tableCell}>{item?.timming}</TableCell>
                                    <TableCell style={{textAlign:"center"}} className={styles.tableCell}>{item?.parking?.spot_number} {item?.address?.society}, {item?.address?.locality}</TableCell>
                                    <TableCell style={{cursor:"pointer"}}  onClick={()=>{setUpdate(true);
                                        setUpdateId(item._id);
                                        setIndividualData({
                                            name:item?.name,
                                            car_number:item?.car_number,
                                            aadhaar_number:item?.aadhaar_number,
                                            timming:item?.timming,
                                            mobile_number:item?.mobile_number,
                                        }); setAddFileView(true)}} ><div style={{display:"flex", justifyContent:"right", width:"100%"}}><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4205 3.3295C14.9811 2.89016 14.2688 2.89016 13.8295 3.3295L13.2348 3.92417L14.8258 5.51516L15.4205 4.92049C15.8598 4.48115 15.8598 3.76884 15.4205 3.3295ZM16.4811 5.98115C17.5062 4.95603 17.5062 3.29397 16.4811 2.26884C15.456 1.24372 13.7939 1.24372 12.7688 2.26884L11.6439 3.3937L2.46967 12.568C2.32902 12.7086 2.25 12.8994 2.25 13.0983V15.7766C2.25 16.1908 2.58579 16.5266 3 16.5266H5.625C5.82391 16.5266 6.01468 16.4476 6.15533 16.3069L16.4811 5.98115ZM13.7651 6.57582L12.1741 4.98483L3.75 13.409V15.0266H5.31434L13.7651 6.57582Z" fill="#111827"/></svg></div>
                                    </TableCell>
                                    <TableCell onClick={()=>{setDeleteView(true); setUpdateId(item._id); setUserName(`${item?.name}, ${item?.car_number}`)}} style={{alignItems:"right", cursor:"pointer"}}><div style={{display:"flex", justifyContent:"right", width:"100%"}} ><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 3C6 2.17157 6.67157 1.5 7.5 1.5H10.5C11.3284 1.5 12 2.17157 12 3V4.5H14.2423C14.2469 4.49996 14.2515 4.49996 14.2562 4.5H15C15.4142 4.5 15.75 4.83579 15.75 5.25C15.75 5.66421 15.4142 6 15 6H14.9483L14.3476 14.4103C14.2635 15.5877 13.2838 16.5 12.1033 16.5H5.89668C4.71624 16.5 3.7365 15.5877 3.6524 14.4103L3.05166 6H3C2.58579 6 2.25 5.66421 2.25 5.25C2.25 4.83579 2.58579 4.5 3 4.5H3.74381C3.74846 4.49996 3.75311 4.49996 3.75774 4.5H6V3ZM4.55548 6L5.14858 14.3034C5.17662 14.6959 5.5032 15 5.89668 15H12.1033C12.4968 15 12.8234 14.6959 12.8514 14.3034L13.4445 6H4.55548ZM10.5 4.5H7.5V3H10.5V4.5ZM7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5ZM10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#111827"/></svg></div>
                                    </TableCell>
                                </TableRow>
                            ))
                            :
                                <TableRow >
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}>No Data</TableCell>
                                    <TableCell style={{textAlign:"center"}} className={styles.tableCell}></TableCell>
                                    <TableCell style={{cursor:"pointer"}} >
                                    </TableCell>
                                    <TableCell onClick={()=>setDeleteView(true)} style={{alignItems:"right", cursor:"pointer"}}>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
                <div style={{width:"100%", display:"flex", marginTop:"18px", justifyContent:"end"}}>
                    <p style={{fontSize:"16px"}}>Page {currentPage} of {maxPage}</p>
                </div>
            </div>
            <div className={styles.buttonContainer} >
                <div className={styles.miniContainer}>
                    <Button variant='secondary' onClick={()=>setUploadFileView(true)}>Upload Records</Button>
                    <Button variant='secondary' onClick={()=>setAddFileView(true)}>Add</Button>
                </div>
                <div className={styles.miniContainer}>
                    <Button onClick={()=> filterSearch({page:currentPage===1 ? 1 :currentPage-1, search:filterData} )} variant='secondary'  icon={FaArrowAltCircleLeft}>Prev</Button>
                    <Button onClick={()=> filterSearch({page:currentPage>=maxPage ? maxPage : currentPage+1, search:filterData} ) } variant='secondary' iconPosition='right' icon={FaArrowAltCircleRight}>Next</Button>
                </div>
            </div>
            {
                uploadFileView &&
                <div className={styles.uploadContainer}>
                    <Card className={styles.card}>
                        {/*  <Title>Please add excel file with columns in this order</Title>
                        <ul>
                            <li>Name</li>
                            <li>Flat No</li>
                            <li>Address</li>
                            <li>Mobile Number</li>
                            <li>Registration Number</li>
                            <li>Model</li>
                            <li>Parking</li>
                        </ul> */}
                        <div className={styles.buttonUploadContainer}>
                            <div className={styles.inputContainer}>
                                <p >{fileName}</p>
                                <MdOutlineUploadFile className={styles.uploadLogo} />
                                <input
                                    type="file"
                                    accept=".csv,.xls,.xlsx"
                                    onChange={handleFileChange}
                                />
                            </div>
                            {
                                errorNumber &&
                                <p style={{color:'red', maxWidth:"350px", textAlign:'center'}}>* {errorNumber} records were corrupt/wrong please download it and recify the mistake and reupload it again</p>
                            }
                            {
                                csvBlob &&
                                <Button color='green' icon={FaFileDownload} onClick={downloadCSV}>Download CSV</Button>
                            }
                            {
                                selectedFile &&
                                <Button onClick={()=>{UploadExcel();}} loading={loading}>Upload</Button>
                            }
                            <Button color='red' onClick={()=>{setUploadFileView(false); setLoading(false);
                                        setSelectedFile(null);
                                        setCsvBlob(null);
                                        setErrorNumber(null);
                                        setFileName("Add File");}} loading={loading}>Cancel Action</Button>
                        </div>
                    </Card>
                </div>
            }
            {
                addFileView &&
                <div className={styles.uploadContainer}>
                    <Card className={styles.card2}>
                        <div className={styles.inputContainer}>
                            <div className={styles.inputs}>
                                <p>Name and Contact</p>
                                <div className={styles.smallContainer}>
                                    <div className={styles.smallContainerTop}>
                                        <TextInput error={errorLog?.name} value={individualData.name} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                name:value
                                            }
                                        ))} placeholder='Enter Cleaner Name' className={styles.input} />
                                        <TextInput error={errorLog?.mobile_number} value={individualData.mobile_number} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                mobile_number:value
                                            }
                                        ))} placeholder='Enter Cleaner Number' className={styles.input} />
                                    </div>
                                    <TextInput error={errorLog?.aadhaar_number} value={individualData.aadhaar_number} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                aadhaar_number:value
                                            }
                                        ))} placeholder='Enter Cleaner Aadhaar' className={styles.input} type='email' />
                                    {/* <TextInput value={individualData.password} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                password:value
                                            }
                                        ))} placeholder='Enter Password' className={styles.input} type='password' /> */}
                                </div>
                            </div>
                            <div className={styles.inputs}>
                                <p>Visit and Car Details</p>
                                <div className={styles.smallContainer}>
                                    <div className={styles.smallContainerTop}>
                                    <LocalizationProvider  dateAdapter={AdapterDayjs}>
                                        <DemoContainer  components={['TimePicker', 'TimePicker']}>
                                            <TimePicker
                                            value={startTime}
                                            minutesStep={15}
                                            label="Select Start Time"
                                            onChange={(newValue) => {setStartTime(newValue as Dayjs | undefined); setIndividualData(prev=>(
                                                {
                                                    ...prev,
                                                    timming:`${newValue?.format('hh:mm A')} To ${endTime?.format('hh:mm A')}`
                                                }
                                            )); console.log(newValue?.format('hh:mm A'))}}
                                            />
                                        </DemoContainer>
                                        {errorLog?.timming && <MdError color='red' size={25}/>}
                                    </LocalizationProvider>
                                    <Title>To</Title>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer  components={['TimePicker', 'TimePicker']}>
                                            <TimePicker
                                                className={styles.ClockDesign}
                                                minutesStep={15}
                                                value={endTime}
                                                minTime={startTime}
                                                label="Select End Time"
                                                onChange={(newValue) =>{setIndividualData(prev=>(
                                                    {
                                                        ...prev,
                                                        timming:`${startTime?.format('hh:mm A')} To ${newValue?.format('hh:mm A')}`
                                                    }
                                                )); setEndTime(newValue as Dayjs | undefined)}}
                                            />
                                        </DemoContainer>
                                        {errorLog?.timming && <MdError color='red' size={25}/>}
                                    </LocalizationProvider>
                                    </div>
                                    <TextInput error={errorLog?.car_number} value={individualData.car_number} onValueChange={(value)=>setIndividualData(prev=>(
                                            {
                                                ...prev,
                                                car_number:value
                                            }
                                        ))} placeholder='Enter Car Number' className={styles.input} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttonContainer}>
                            <div></div>
                            <div className={styles.miniContainer}>
                                <Button onClick={()=>{setAddFileView(false); setIndividualData(initialData); setErrorLog(intialStateErrorLog)}} variant='secondary'>Cancel</Button>
                                <Button onClick={()=>
                                        update ?
                                        uploadUpdateFile({
                                            name: individualData.name,//0 as unknown as string,
                                            mobile_number:individualData.mobile_number,
                                            aadhaar_number:individualData.aadhaar_number,
                                            timming:individualData.timming,
                                            updated_by:"admin@example.com",
                                            car_number:individualData.car_number,
                                            created_by:{
                                                type:"admin",
                                                uploaded_file_url:""
                                            }
                                        })
                                :
                                    uploadIndividualFile({
                                        name: individualData.name,//0 as unknown as string,
                                        mobile_number:individualData.mobile_number,
                                        aadhaar_number:individualData.aadhaar_number,
                                        timming:individualData.timming,
                                        updated_by:"admin@example.com",
                                        car_number:individualData.car_number,
                                        created_by:{
                                            type:"admin",
                                            uploaded_file_url:""
                                        }
                                    })} >Add</Button>
                            </div>
                        </div>
                    </Card>
                </div>
            }
            {
                deleteView &&
                <div className={styles.uploadContainer}>
                    <Card className={styles.deleteCard}>
                        <div className={styles.detailSection}>
                            <p>Are you sure you want to delete record of</p>
                            <Title style={{color:"black"}}>{userName}</Title>
                        </div>
                        <div className={styles.deleteButtons}>
                            <Button style={{fontSize:"14px"}} onClick={()=>{setDeleteView(false)}} variant='secondary'>Cancel</Button>
                            <Button style={{fontSize:"14px"}} onClick={()=>{DeleteFile(updateId)}} color='red'>Delete</Button>
                        </div>
                    </Card>
                </div>
            }
        </div>
    )
}
